<script>
import Aws from '@/services/Aws';
import { required} from "vuelidate/lib/validators";
import validationMessages from '@/components/validations'
import Swal from "sweetalert2";


export default {
  components:{
    validationMessages
  },
  props: ['securityGroupData'],
  data() {
    return {
      csrf_token: localStorage.getItem('csrf_token'),
      submitted: false,
      showModal: false,
      tryingToEdit: false,
      showLoader:false,
      modalTitle:'',
      cidrIp: '',
      description:'',
      updatingData: false,
      ingressRules: [],
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "CidrIp",
      sortDesc: false,
      isBusy: false,
      totalRows: 0,
      fields: [
        {
          key: "IpProtocol",
          label: "IP Protocol",
          sortable: true,
        },
        {
          key: "FromPort",
          label: "From Port",
          sortable: false,
        },
        {
          key: "ToPort",
          label: "To Port",
          sortable: false,
        },
        {
          key: "CidrIp",
          label: "IP Ranges",
          sortable: false,
        },
        {
          key: "Description",
          label: "Description",
          sortable: false,
        },
        "action",
      ],
    };
  },
  watch: {
    securityGroupData(newData) {
      if (newData) {
        this.cidrIp = newData.cidrIp || '';
        this.description = newData.description || '';
        this.showData();
      }
    }
  },
  validations: {
    cidrIp: {
      required,
      valid: function(value) {
        return this.validIp(value)
      },
    },
  },
  methods: {

    async addIngressRule(){

      this.tryingToEdit = true;
      this.submitted = true;
      this.$v.$touch();

      if (this.$v.$invalid) {
        this.tryingToEdit = false;
        return;
      } else {
        try {
          this.isBusy = true
          await Aws.addSecurityGroupRule({
            GroupId: this.securityGroupData.GroupId,
            CidrIp: this.cidrIp,
            Description: this.description,
            csrf_token: this.csrf_token
          }).then((response) => {
            this.successmsg("New security group rule has been created");
          }).catch(error => {
            this.error = error.response.data.error ? error.response.data.error : "";
            this.failedmsg(this.error);
          }).finally(() => {
            this.refreshData();
            setTimeout(() => {
              this.tryingToEdit = false
              this.submitted = false
            }, 3000);
            this.isBusy = false
          });
        } catch (error) {
          this.error = error.response.data.error ? error.response.data.error : "";
          this.failedmsg(this.error);
        }
      }

      this.tryingToEdit = false;
    },

    async deleteIngressRule(cidrIp, description){
      Swal.fire({
        title: "Delete Rule " + cidrIp + " for Group: " + this.securityGroupData.GroupName,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Delete",

      }).then((result) => {
        if (result.value) {
          this.tryingToEdit = true
          this.isBusy = true
          Aws.removeSecurityGroupRule({
            "GroupId": this.securityGroupData.GroupId,
            "CidrIp": cidrIp
          }).then((response) => {

            const res = response.data.data ? response.data.data : false;
            const error = response.data.error ? response.data.error : '';
            if (res && error == '') {
              this.refreshData()
              this.successmsg("Security group rule successfully deleted")
            } else {
              this.failedmsg("Fail to delete security group rule!")
            }
          }).catch((error) => {
              let errorMsg = error.response.data.error
                      ? error.response.data.error
                      : "";
              this.failedmsg("Fail!", errorMsg);
          }).finally(() => {
            setTimeout(() => {
              this.tryingToEdit = false
              this.submitted = false
            }, 3000);
            this.isBusy = false
          });
        }
      });
    },

    refreshData() {
      this.$emit('onRefresh', this.securityGroupData.GroupId, 'updateData')
    },

    closeModal() {
      this.showModal = false;
    },

    resetData(){
      this.cidrIp = ''
      this.description = ''
    },

    showData(){
      this.ingressRules = this.securityGroupData.IngressRules.flatMap(rule => {
        return rule.IpRanges.map(ipRange => ({
          IpProtocol: rule.IpProtocol,
          FromPort: rule.FromPort,
          ToPort: rule.ToPort,
          CidrIp: ipRange.CidrIp,
          Description: ipRange.Description
        }));
      });

      this.totalRows = this.ingressRules.length;
    },

    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

  }
};
</script>

<template>
  <b-modal @shown="showData" @hide="resetData" v-model="showModal" id="changeSecurityGroupRules_edit"
           :title="`Change Security Group Ingress Rules (${securityGroupData.GroupName} - ${securityGroupData.GroupId})`"
           title-class="font-18" size="xl" centered>
    <form @submit.prevent="addIngressRule">
      <div class="row">
        <div class="col-sm-12">
          <h5>Add New Security Group Rule</h5>
        </div>

        <div class="col-sm-6 col-md-4">
          <div class="form-group">
            <label for="CidrIp">Public IP - (Optional CIDR Format) :</label>
            <input type="text" id="CidrIp"
                   placeholder="xxx.xxx.xxx.xxx"
                   class="form-control"
                   v-model="cidrIp"
                   :class="{
                      'is-invalid': submitted && $v.cidrIp.$error,
                    }"
            />
            <validationMessages v-if="submitted" :fieldName="'CIDR IP'" :validationName="$v.cidrIp"></validationMessages>
          </div>
        </div>
        <div class="col-sm-6 col-md-6">
          <div class="form-group">
            <label for="description">Description :</label>
            <input type="text" id="description"
                   class="form-control"
                   v-model="description"
            />
          </div>
        </div>
        <div class="col-sm-6 col-md-2 align-content-end">
          <b-button variant="primary" @click="addIngressRule" :disabled="tryingToEdit" >
            <b-spinner v-show="tryingToEdit" small></b-spinner>
            Add New Rule</b-button>
        </div>
      </div>
    </form>
    <hr class="mt-3">
    <div class="row mt-5">
      <div class="col-sm-6">
        <h5>{{securityGroupData.GroupName}} - Security Group Rules</h5>
      </div>
      <div class="col-sm-6">
        <h5>Total Security Rules: {{totalRows}}</h5>
      </div>


        <div class="col-sm-12 col-md-3">
          <div class="dataTables_length">
            <label class="d-inline-flex align-items-center">
              Show&nbsp;
              <b-form-select
                      v-model="perPage"
                      size="sm"
                      :options="pageOptions"
              ></b-form-select
              >&nbsp;entries
            </label>
          </div>
        </div>

        <!-- Search -->
        <div class="col-sm-12 col-md-9">
          <div
                  id="tickets-table_filter"
                  class="dataTables_filter text-md-end"
          >
            <label class="d-inline-flex align-items-center">
              Search:
              <b-form-input
                      v-model="filter"
                      type="search"
                      placeholder="Search..."
                      class="form-control form-control-sm ms-2"
              ></b-form-input>
            </label>
          </div>
        </div>

      <div class="col-md-12 table-responsive">
        <b-table
                :busy="isBusy"
                :items="ingressRules"
                :fields="fields"
                responsive="sm"
                :per-page="perPage"
                :current-page="currentPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter="filter"
                :filter-included-fields="filterOn"
                show-empty
                empty-text="No Data Found"
                @filtered="onFiltered"
        >

          <template v-slot:cell(action)="data">
            <ul class="list-inline mb-0">
              <li class="list-inline-item">
                <a
                        href="javascript:void(0);"
                        class="px-2 text-danger"
                        title="Delete Security Group Rule"
                        @click="deleteIngressRule(data.item.CidrIp)" >
                  <i class="uil uil-trash-alt font-size-18"></i>
                </a>
              </li>
            </ul>
          </template>

          <template #table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong>Loading...</strong>
            </div>
          </template>

        </b-table>

      </div>
    </div>
    <div class="row">
      <div class="col">
        <div class="dataTables_paginate paging_simple_numbers float-end">
          <ul class="pagination pagination-rounded mb-0">
            <!-- pagination -->
            <b-pagination
                    v-model="currentPage"
                    :total-rows="totalRows"
                    :per-page="perPage"
            ></b-pagination>
          </ul>
        </div>
      </div>
    </div>

    <template v-slot:modal-footer>
      <b-button variant="light" @click="closeModal">Close</b-button>
    </template>
  </b-modal>
</template>